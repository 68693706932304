.footer {
    background-color: black;
    position: absolute;
    color: white;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.footer p {
    padding-left: 5%;
    float: left;
}